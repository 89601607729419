<template>
	<div>
<!--    Hidden here hides the space that we have under the filterbar -->
    <div hidden v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'">
      <v-row >
        <v-dialog v-model="dialogDelete" max-width="380px"  transition="dialog-top-transition">
          <v-card class="pa-7">
            <v-card-title class="d-flex justify-center align-center">
              <v-row  dense>
                <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background-alert">
                  <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
                </v-col>
                <v-col cols="12">
                  <span>{{ $t('deleteQuestion') }}</span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
            <v-card-actions class="mb-2">
              <v-row dense>
                <v-col cols="12">
                  <v-btn block depressed color="red" class="text-color-white"  @click="deleteUserConfirm">{{ $t('deleteField') }}</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn outlined depressed color="black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
<!--      <v-row>-->
<!--			<v-col cols="12">-->
<!--				<v-dialog v-model="dialogDelete" max-width="450px">-->
<!--					<v-card>-->
<!--						<v-card-title class="text-h6">Are you sure you want to delete this item?</v-card-title>-->
<!--						<v-card-actions>-->
<!--							<v-spacer></v-spacer>-->
<!--							<v-btn color="primary" text @click="closeDeleteDialog">{{ $t("no") }}</v-btn>-->
<!--							<v-btn color="red darken-1" text @click="deleteUserConfirm">{{ $t("yes") }}</v-btn>-->
<!--							<v-spacer></v-spacer>-->
<!--						</v-card-actions>-->
<!--					</v-card>-->
<!--				</v-dialog>-->
<!--			</v-col>-->
<!--		</v-row>-->
    </div>
		<v-row>
			<v-col cols="12">
				<v-card flat >
					<v-data-table
						v-if="filteredHospitalAdmins"
						class="ma-4 border-admins-data-table"
						:loading="loading"
						:headers="headers"
						:headers-length="headers.length"
						:search="appBarSearch"
						:no-data-text="$t('no-data-available')"
						:loading-text="$t('loading')"
						:items="filteredHospitalAdmins"
						:items-per-page="-1"
						sort-by="id"
						item-key="id"
						fixed-header
						dense
						@click:row="selectedHospitalAdminRow"
						:height="pageHeight(135)"
						:footer-props="{
							showCurrentPage: false,
							showFirstLastPage: false,
							itemsPerPageOptions: [filteredHospitalAdmins.length],
							itemsPerPageText: null
						}"
						>
							<template v-slot:item="{ item }">
								<tr @click="selectedHospitalAdminRow(item)">
									<td>
										<div class="d-flex align-center font-size13">
											<span class="font-bold">{{ item.firstName }} {{ item.lastName }}</span>
										</div>
									</td>
									<td class="text-left">
											<span>{{ item.hospitalAssignedData === null ? "" : item.hospitalAssignedData.hospitalName }}</span>
									</td>
									<td>
										<div class="d-flex align-center">
										<v-icon size="18" class="color-primary mr-2">mdi-email</v-icon>
											<span class="color-primary font-size13">{{ item.email }}</span>
										</div>
									</td>
									<td>
										<div class="d-flex align-center">
										<v-icon class="color-primary mr-2" size="18">mdi-phone</v-icon>
											<span class="color-primary font-size13">{{ item.phone }}</span>
										</div>
									</td>
									<td>
                    <div class="d-flex align-center justify-end">
										<v-icon small class="mr-4" :style="item.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
										<v-menu
											left
											bottom
                      class="py-0"
											offset-y
											transition="slide-x-transition"
										>
                      <template v-slot:activator="{on, attrs}">
                        <v-btn
                            icon
                            color="black"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <span class="mdi mdi-24px mdi-dots-horizontal "></span>
                        </v-btn>
                      </template >

                      <v-list class="px-0" dense >
												<v-list-item class="py-0 my-0" link dense @click="handleActionView(item)">
                          <v-list-item-icon class="mx-1 ">
                            <v-icon class="icon-img mdi-18px" color="black">mdi-eye</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="font-bold font-size14">{{$t("view")}}</v-list-item-content>
												</v-list-item>
												<v-list-item link dense @click="handleToggleStatus(item)">
                          <v-list-item-icon class="mx-1 ">
                            <v-icon class="icon-img mdi-18px" color="black">mdi-refresh</v-icon>
                          </v-list-item-icon>
													<v-list-item-content class="font-bold font-size14" >{{ $t('change') }} {{ $t('status') }}</v-list-item-content>
												</v-list-item>
												<v-list-item link dense @click="handleActionSendEmail(item)">
                          <v-list-item-icon class="mx-1 ">
                            <v-icon class="icon-img mdi-18px" color="black">mdi-lock-reset</v-icon>
                          </v-list-item-icon>
													<v-list-item-content class="font-bold font-size14" v-if="item.status !== 1" label @click.stop="sendActivationEmail(item)">{{ $t('send-activation-email') }}</v-list-item-content>
													<v-list-item-content class="font-bold font-size14 text-left" v-else label @click.stop="sendActivationEmail(item)">{{ $t('send-reset-password-email') }}</v-list-item-content>
												</v-list-item>
                        <v-divider/>
												<v-list-item v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'" link dense @click="handleActionDelete(item)">
                          <v-list-item-icon class="mx-1 ">
                            <v-icon color="red">mdi-trash-can-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="font-bold font-size14" >{{ $t('delete') }}</v-list-item-content>
												</v-list-item>
											</v-list>
										</v-menu>
                    </div>
									</td>
								</tr>
							</template>
							<template v-slot:[`footer.prepend`]>
							</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mailPurple, phonePurple } from '@/assets';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
	components: {},
	data () {
		return {
			loading: true,
			headerClass: 'color-header',
			selectedItem: {},
			mailPurple: mailPurple,
			phonePurple: phonePurple,
			dialogDelete: false,
			// userIdToDelete: -1,
			// selectedUserRoleId: -1,
		};
	},
	computed: {
		...mapGetters({
			roleName: 'authentication/getRole',
			delay: 'authentication/getDelay',
			pageHeight: 'pageHeight',
			appBarSearch: 'filterbar/getSearch',
			appBarMedicalInstitution: 'filterbar/getMedicalInstitution',
			appBarUserStatusVerifier: 'filterbar/getUserStatusVerifier',
			appBarRole: 'filterbar/getRole',
		}),
		...mapState({
			organizationAdmins: (state) => state.users.organizationAdmins,
			hospitalAdminUsers: (state) => state.users.hospitalAdmins,
			caregivers: (state) => state.users.caregivers,
			allVerifiers: (state) => state.verifiers.allverifires,
			allRoles: (state) => state.roles.roles,
		}),
		height () {
			return window.innerHeight - 250;
		},
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 320;
			case 'sm':
				return window.innerHeight - 300;
			case 'md':
				return window.innerHeight - 300;
			case 'lg':
				return window.innerHeight - 280;
			case 'xl':
				return window.innerHeight - 280;
			default:
				return 0;
			}
		},
		headers () {
			var headers = [];
			headers = [
				{
					text: this.$t('header-full-name'),
					value: 'firstName',
					class: this.headerClass,
				},
				{
					text: this.$t('header-hospital-name'),
					value: 'hospitalAssignedData?.hospitalName',
					class: this.headerClass,
				},

				{
					text: this.$t('header-email'),
					value: 'email',
					class: this.headerClass,
				},
				{
					text: this.$t('header-phone'),
					value: 'phone',
					class: this.headerClass,
				},
				{
					text: '',
					value: '',
					class: this.headerClass,
          sortable: false,
				},
			];
			return headers;
		},
		filteredHospitalAdmins () {
			let calculateFileteredHospitalAdmins = [];
			if (this.organizationAdmins) {
				this.organizationAdmins.forEach((element) => {
					calculateFileteredHospitalAdmins.push(element);
				});
			}

			if (this.hospitalAdminUsers) {
				this.hospitalAdminUsers.forEach((element) => {
					calculateFileteredHospitalAdmins.push(element);
				});
			}

			if (this.caregivers) {
				this.caregivers.forEach((element) => {
					calculateFileteredHospitalAdmins.push(element);
				});
			}

			if (this.appBarMedicalInstitution !== 0 && this.appBarMedicalInstitution !== null) {
				// filter by medicalInstitutionId
				calculateFileteredHospitalAdmins = calculateFileteredHospitalAdmins.filter(u => u.organizationId === this.appBarMedicalInstitution);
			}

			if (this.appBarUserStatusVerifier !== -99 && this.appBarUserStatusVerifier !== null) {
				// filter by userStatusId
				calculateFileteredHospitalAdmins = calculateFileteredHospitalAdmins.filter(u => u.status === this.appBarUserStatusVerifier);
			}

			if (this.appBarRole !== -99 && this.appBarRole !== null) {
				// filter by userStatusId
				calculateFileteredHospitalAdmins = calculateFileteredHospitalAdmins.filter(u => u.roleId === this.appBarRole);
			}

			return calculateFileteredHospitalAdmins;
		},
	},
	async mounted () {
		await this.getOrganizationAmdinUsers();
		await this.getHospitalAmdinsUsers();
		// await this.getCaregivers();
		await this.getAllVerifiers();
		await this.getAllRoles();
	},
	methods: {
		async getOrganizationAmdinUsers () {
			await this.$store.dispatch('users/getOrganizationAdmins').then(() => {
				this.loading = false;
			});
		},
		async getHospitalAmdinsUsers () {
			await this.$store.dispatch('users/getHospitalAdmins').then(() => {
				this.loading = false;
			});
		},
		async getCaregivers () {
			await this.$store.dispatch('users/getCaregivers').then(() => {
				this.loading = false;
			});
		},
		async getAllVerifiers () {
			// we call this API endpoint only if the store values are null
			if (this.allVerifiers == null) {
				await this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
					// this.loading = false;
				});
			}
		},
		async getAllRoles () {
			// we call this API endpoint only if the store values are null
			if (this.allRoles == null) {
				await this.$store.dispatch('roles/getRoles').then(() => {
					// this.loading = false;
				});
			}
		},
		async editHospitalAdmin (item) {
			// await this.$store.commit('users/SELECTED_HOSPITAL_ADMIN', item); // get from the store, not from API call

			if (item.roleData.roleName === 'HOSPITAL_ADMIN') {
				this.$store.commit('users/SELECTED_ORGANIZATION_ADMIN'); // set selectedHospitalAdmin = null
				this.$store.commit('users/SELECTED_CARE_GIVER'); // set selectedCaregiver = null

				await this.$store.commit('users/SELECTED_HOSPITAL_ADMIN', item); // get from the store, not from API call
			} else if (item.roleData.roleName === 'ORGANIZATION_ADMIN') {
				this.$store.commit('users/SELECTED_HOSPITAL_ADMIN'); // set selectedHospitalAdmin = null
				this.$store.commit('users/SELECTED_CARE_GIVER'); // set selectedCaregiver = null

				await this.$store.commit('users/SELECTED_ORGANIZATION_ADMIN', item); // get from the store, not from API call
			} else if (item.roleData.roleName === 'CARE_GIVER') {
				this.$store.commit('users/SELECTED_ORGANIZATION_ADMIN'); // set selectedHospitalAdmin = null
				this.$store.commit('users/SELECTED_HOSPITAL_ADMIN'); // set selectedHospitalAdmin = null

				await this.$store.commit('users/SELECTED_CARE_GIVER', item); // get from the store, not from API call
			}

			this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
		},
		selectedHospitalAdminRow (item) {
			this.editHospitalAdmin(item);
		},
		async sendActivationEmail (item) {
			const sendValidationEmail = { email: item.email };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
						showSuccessAlert(this.$t('success-operation'));
					} else {
						showErrorAlert(this.$t('failed-operation'));
					}
				});
		},
		handleActionView (item) {
			this.editHospitalAdmin(item);
		},
		async handleToggleStatus (item) {
			if (item.status === -1) {
				// not validated user
				// showErrorAlert(this.$t('failed-operation'));
				showErrorAlert(this.$t('not-activated-user'));
				return;
			}

			const params = { userId: item.id, statusValueToSet: item.status === 1 ? 0 : 1 };
			await this.$store.dispatch('users/setUserStatus', params)
				.then(res => {
					if (res.resFlag) {
						showSuccessAlert(this.$t('success-operation'));

						if (item.roleId === 6) {
							this.getHospitalAmdinsUsers(); // reload the list of hospital admins
						} else if (item.roleId === 8) {
							this.getOrganizationAmdinUsers(); // reload the list of organization admins
						} else if (item.roleId === 7) {
							this.getCaregivers(); // reload the list of caregivers
						}
					} else {
						showErrorAlert(this.$t('failed-operation'));
					}
				});
		},
		handleActionSendEmail (item) {
			this.sendActivationEmail(item);
		},
		async handleActionDelete (item) {
			this.dialogDelete = true;
			this.selectedItem = item;
		},
		async deleteUserConfirm () {
			await this.$store.dispatch('users/deleteUser2', this.selectedItem)
				.then(res => {
					if (res.resFlag) {
						showSuccessAlert(this.$t('success-operation'));

						if (this.selectedItem.roleId === 6) {
							this.getHospitalAmdinsUsers(); // reload the list of hospital admins
						} else if (this.selectedItem.roleId === 8) {
							this.getOrganizationAmdinUsers(); // reload the list of organization admins
						} else if (this.selectedItem.roleId === 7) {
							this.getCaregivers(); // reload the list of caregivers
						}
						this.closeDeleteDialog();
					} else {
						showErrorAlert(this.$t('failed-operation'));
					}
				});
		},
		closeDeleteDialog () {
			this.dialogDelete = false;
			this.selectedItem = {};
		},
	},
	watch: {
		async appBarMedicalInstitution (val) {
			// after filtering
		},
		async appBarUserStatusVerifier (val) {
			// after filtering
		},
		async appBarRole (val) {
			// after filtering
		},
	},
};
</script>
<style>

</style>
